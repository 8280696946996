import styled from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

import { InputStyled } from "components/ui/input/Input.styled";

export const Container = styled.div`
    flex: 1;
    width: 100%;
    padding: 35px 20px 0;

    ${InputStyled} {
        margin: 0;
    }

    ${media.mdUp} {
        max-width: ${columns(6)};
        padding: 0;
    }
`;

export const Title = styled.h2`
    margin: 0;

    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);

    ${media.mdUp} {
        margin: 0 0 10px;

        color: var(--color-black);
    }
`;
export const Description = styled.p`
    margin: 0 0 10px;
    padding: 0;

    color: var(--color-white);

    ${media.mdUp} {
        color: var(--color-black);
        font-size: var(--fot-size-body);
        line-height: var(--line-height-body);
    }
`;
