import styled, { css } from "styled-components";

export const VisuallyHidden = styled.span`
    /* stylelint-disable-next-line declaration-no-important */
    position: absolute !important;

    width: 1px;
    height: 1px;
    overflow: hidden;

    white-space: nowrap;

    clip: rect(1px, 1px, 1px, 1px);
`;

export const hideScrollbar = css`
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }
`;

/* prettier-ignore */
export const columns = columns => `
    calc((${columns} * var(--1-column-width)) + (${columns - 1} * var(--gutter)))
`;
