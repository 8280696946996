import Container from "components/ui/container/Container";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import PaymentMethods from "../paymentMethods/PaymentMethods";
import Shipment from "../shipment/Shipment";
import Contact from "../contact/Contact";
import Links from "../links/Links";
import Terms from "../terms/Terms";
import Newsletter from "../newsletter/Newsletter";

import { StyledFooter, Row } from "./DesktopFooter.styled";

const navItems = [
    {
        url: "/helpdesk/",
        label: "Veelgestelde Vragen"
    },
    {
        url: "/over-ons/",
        label: "Over ons"
    },
    {
        url: "/theaterbon/",
        label: "Theaterbon"
    },
    {
        url: "/cultuurkaart/",
        label: "Cultuurkaart"
    },
    {
        url: "https://www.cadeaubon.nl/",
        label: "Bestel op Cadeaubon.nl"
    },
    {
        url: "/acceptant-worden/",
        label: "Acceptant worden"
    },
    {
        url: "/algemene-voorwaarden/",
        label: "Algemene voorwaarden"
    },
    {
        url: "/privacy/",
        label: "Privacyverklaring"
    }
];

function FooterDesktop() {
    const { desktopView } = useMediaQueryContext();

    return (
        <StyledFooter>
            <Container>
                <Row>
                    {desktopView && <Terms />}
                    <Contact />
                    <Newsletter />
                </Row>
                <Row>
                    <Links items={navItems} />
                </Row>
                <Row>
                    <PaymentMethods />
                    <Shipment />
                </Row>
                {!desktopView && (
                    <Row>
                        <Terms />
                    </Row>
                )}
            </Container>
        </StyledFooter>
    );
}

export default FooterDesktop;
