import styled from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

export const ShipmentContainer = styled.div`
    display: flex;
    align-items: center;
    max-width: 300px;
    margin: 0;
    padding: 35px 20px 0;

    ${media.mdUp} {
        flex-flow: row wrap;
        gap: 0 30px;
        width: ${columns(6)};
        margin: 0;
        padding: 0;
    }

    ${media.lgUp} {
        flex: 1 0 auto;
        flex-flow: row nowrap;
        width: unset;
        min-width: ${columns(5)};
    }
`;

export const Title = styled.h2`
    width: 100%;
    margin: 0 0 20px;

    color: var(--color-black);
    font-size: var(--font-size-h6);
    line-height: var(--line-height-h6);

    ${media.mobileHide}

    ${media.lgUp} {
        width: unset;
        margin: 0;
    }
`;

export const Logo = styled.img`
    width: 50px;
    height: 50px;
`;

export const Text = styled.p`
    margin: 0 0 0 25px;

    color: var(--color-white);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    ${media.mdUp} {
        max-width: 170px;
        margin: 0;

        color: var(--color-tertiary);
    }
`;
