import styled from "styled-components";

import { columns } from "theme/mixins";

import media from "constants/media";

export const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0 20px;
    align-items: center;
    justify-content: center;
    padding: 35px 20px 0;

    text-align: center;

    ${media.mdUp} {
        flex: 1 1 auto;
        justify-content: flex-start;
        max-width: ${columns(6)};
        padding: 0;

        text-align: left;
    }

    ${media.lgUp} {
        flex-flow: row nowrap;
        max-width: ${columns(7)};
    }
`;

export const Title = styled.h2`
    width: 100%;
    margin: 0 0 10px;

    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h6);
    line-height: var(--line-height-h6);

    ${media.mdUp} {
        margin: 0;

        color: var(--color-black);
    }

    ${media.lgUp} {
        width: unset;
    }
`;

export const Method = styled.img`
    ${media.mdUp} {
        transform: translateX(-15px);
    }

    ${media.lgUp} {
        transform: translateX(0);
    }
`;
