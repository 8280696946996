import { Container, Title, Method } from "./PaymentMethods.styled";

function PaymentMethods() {
    return (
        <Container>
            <Title>Betaalmogelijkheden</Title>
            <Method
                src="/images/payment-methods/logo-ideal.svg"
                alt="Logo Ideal"
                width={60}
                height={60}
                loading="lazy"
            />
            <Method
                src="/images/payment-methods/logo-mastercard.svg"
                alt="Logo Mastercard"
                width={60}
                height={60}
                loading="lazy"
            />
            <Method
                src="/images/payment-methods/logo-visa.svg"
                alt="Logo Visa"
                width={60}
                height={60}
                loading="lazy"
            />
            <Method
                src="/images/payment-methods/logo-payconiq.svg"
                alt="Logo Payconiq"
                width={60}
                height={60}
                loading="lazy"
            />
        </Container>
    );
}

export default PaymentMethods;
