import PropTypes from "prop-types";

import NewsletterInput from "./newsletterInput/NewsletterInput";

import { Container, Title, Description } from "./Newsletter.styled";

function Newsletter({ inverted }) {
    return (
        <Container inverted={inverted}>
            <Title>Nieuwsbrief</Title>
            <Description>
                Blijf op de hoogte van alle ontwikkelingen en acties!
            </Description>
            <NewsletterInput />
        </Container>
    );
}

Newsletter.propTypes = { inverted: PropTypes.bool };

Newsletter.defaultProps = { inverted: false };

export default Newsletter;
