import styled from "styled-components";

import media from "constants/media";

export const Navigation = styled.nav`
    width: 100%;
    padding: 35px 20px 0;

    ${media.mdUp} {
        flex: 1 1 auto;
        padding: 0;
    }
`;

export const FooterLinkList = styled.ul`
    display: grid;
    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;
    border-bottom: 1px solid rgba(214, 225, 230, 0.2);

    ${media.mdUp} {
        grid-auto-flow: row dense;
        grid-template-columns: repeat(3, 1fr);

        border-bottom: none;
    }

    ${media.lgUp} {
        grid-auto-flow: column dense;
        grid-template: repeat(2, 1fr) / repeat(4, 1fr);
    }
`;

export const Item = styled.li`
    border-top: 1px solid rgba(214, 225, 230, 0.2);

    ${media.mdUp} {
        border-top: none;
    }
`;

export const FooterLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 5px 15px 10px;

    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    @media (any-hover: hover) {
        :hover,
        :focus {
            color: var(--color-primary);
        }
    }

    ${media.mdUp} {
        padding: 3px 0;

        color: var(--color-secondary);
    }
`;
