import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client/react/hooks";
import { useForm } from "react-hook-form";

import { NewsLetterSignUp } from "lib/api/cc/mutations/newsletter.gql";

import Input from "components/ui/input/Input";
import IconChevronRight from "components/ui/icons/IconChevronRight";
import IconCheckmark from "components/ui/icons/IconCheckmark";

function NewsletterInput() {
    const { handleSubmit, register, errors } = useForm();
    const [successSubmit, setSuccesSubmit] = useState(false);

    useEffect(() => {
        let succesTimeout;

        if (successSubmit) {
            succesTimeout = setTimeout(() => {
                setSuccesSubmit(prevState => !prevState);
            }, 2000);
        }

        return () => {
            if (succesTimeout) {
                clearTimeout(succesTimeout);
            }
        };
    });

    const [signUp, { loading }] = useMutation(NewsLetterSignUp, {
        onCompleted: () => {
            setSuccesSubmit(prevState => !prevState);
        }
    });

    function onSubmit(data) {
        signUp({
            variables: {
                ...data,
                optIn: true
            }
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input
                disabled={loading}
                label="Nieuwsbrief"
                name="email"
                hideLabelText
                placeholder="Vul hier je e-mailadres in"
                ref={register({
                    pattern: {
                        value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                        message: "Voer een geldig emailadres in"
                    }
                })}
                errorMessage={errors?.email?.message}
                rightIcon={
                    successSubmit ? (
                        <IconCheckmark size={25} fill="var(--color-green-50)" />
                    ) : (
                        <IconChevronRight
                            size={25}
                            onClick={handleSubmit(onSubmit)}
                            ariaLabel="submit icon"
                        />
                    )
                }
            />
            <input type="submit" hidden disabled={loading} />
        </form>
    );
}

export default NewsletterInput;
