import styled from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

export const Container = styled.div`
    padding: 35px 20px 0;

    ${media.mdUp} {
        width: 100%;
        max-width: ${columns(6)};
        margin: 0;
        padding: 0;
    }

    ${media.lgUp} {
        flex: 1 1 auto;
        max-width: ${columns(4)};
        margin: 0;
        padding: 0;
    }
`;

export const Title = styled.h2`
    margin: 0 0 20px;

    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);

    ${media.mobileHide}
`;

export const SubTitle = styled.p`
    margin: 0;

    color: var(--color-white);
    font-weight: bold;
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    a {
        color: inherit;
        font-weight: inherit;
    }

    ${media.mdUp} {
        margin: 0 0 5px;

        color: var(--color-black);
    }
`;

export const Text = styled.p`
    margin: 0;

    color: var(--color-white);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    ${media.mdUp} {
        color: var(--color-black);
    }
`;
