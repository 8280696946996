import Link from "next/link";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import { Container, Logo, Copyright, Policy, PolicyLink } from "./Terms.styled";

function Terms() {
    const currentYear = new Date().getFullYear();
    const { mobileView } = useMediaQueryContext();

    return (
        <Container aria-label="Algemene voorwaarden">
            <Logo
                src={`/images/logos/logo-kcc-${
                    mobileView ? "white" : "color"
                }.svg`}
                alt="Nationale Kunst & Cultuur Cadeaukaart logo"
                width={151}
                height={43}
                loading="lazy"
            />
            <Copyright>
                &copy; Nationale Kunst & Cultuur Cadeaukaart {currentYear}
            </Copyright>
            {mobileView && (
                <Policy>
                    <li>
                        <Link
                            href="/algemene-voorwaarden/"
                            passHref
                            prefetch={false}
                        >
                            <PolicyLink>
                                {mobileView
                                    ? "Voorwaarden"
                                    : "Algemene voorwaarden"}
                            </PolicyLink>
                        </Link>
                    </li>
                    <li>
                        <Link href="/privacy/" passHref prefetch={false}>
                            <PolicyLink>Privacyverklaring</PolicyLink>
                        </Link>
                    </li>
                </Policy>
            )}
        </Container>
    );
}

export default Terms;
