import PropTypes from "prop-types";
import Link from "next/link";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import IconChevronRight from "components/ui/icons/IconChevronRight";

import { Navigation, FooterLinkList, FooterLink, Item } from "./Links.styled";

function Links({ items }) {
    const { mobileView } = useMediaQueryContext();

    return (
        <Navigation aria-label="Footer">
            <FooterLinkList>
                {items.map(item => (
                    <Item key={JSON.stringify(item)}>
                        <Link href={item.url} passHref prefetch={false}>
                            <FooterLink>
                                {item.label}
                                {mobileView && (
                                    <IconChevronRight
                                        size={27}
                                        fill="currentColor"
                                    />
                                )}
                            </FooterLink>
                        </Link>
                    </Item>
                ))}
            </FooterLinkList>
        </Navigation>
    );
}

Links.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired
};

export default Links;
