import styled from "styled-components";

import media from "constants/media";

export const StyledFooter = styled.footer`
    position: relative;

    width: 100%;
    margin: 100px 0 0;

    background-color: var(--color-white);
    border-top: 2px solid rgba(0, 0, 0, 0.05);

    ${media.mdUp} {
        margin: 0;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: flex-end;

    width: 100%;
    padding: 30px 0;

    :not(:first-child) {
        border-top: 1px solid var(--color-gray-75);
    }

    ${media.mdUp} {
        flex-flow: row wrap;
        gap: 0 20px;
        justify-content: space-between;
    }

    ${media.lgUp} {
        flex-flow: row nowrap;
        padding: 50px 0;
    }
`;
