import styled from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 20px 40px;

    ${media.mdUp} {
        flex-direction: row;
        padding: 0;
    }

    ${media.lgUp} {
        flex: 1 0 auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: ${columns(4)};
        margin-top: 0;
        padding: 0;

        border-top: none;
    }
`;

export const Logo = styled.img`
    width: 151px;
    margin: 0 0 20px;

    ${media.mdUp} {
        width: 100px;
        height: 32px;
        margin: 0;
    }
`;

export const Copyright = styled.div`
    margin: 0 0 15px;

    color: var(--color-white);
    font-size: 12px;
    line-height: var(--line-height-body);

    ${media.mdUp} {
        margin: 0;

        color: var(--color-black);
        font-size: 13px;
    }

    ${media.lgUp} {
        margin: 9px 0 0;
    }
`;

export const Policy = styled.ul`
    display: flex;
    gap: 0 20px;
    margin: 0;
    padding: 0;

    text-align: center;

    list-style: none;

    ${media.mobileOnly}
`;

export const PolicyLink = styled.a`
    display: inline-block;
    padding: 5px 0;

    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
`;
