import styled, { css } from "styled-components";

export const Container = styled.footer`
    height: ${({ open }) => (open ? "100%" : "45px")};

    overflow: hidden;

    background-color: var(--color-secondary);
    transform-origin: top;

    transition: height 0.3s var(--animation-easing-decelerate);
`;

export const BlobContainer = styled.div`
    position: relative;

    display: flex;
    justify-content: center;
    height: 33px;

    background-color: var(--color-white);
`;

export const BlobImage = styled.img`
    width: 124px;
    height: 38px;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 8px;

    transform: rotate(180deg) translateX(1px);

    transition: transform 250ms linear;

    ${({ open }) =>
        open &&
        css`
            transform: rotate(0deg) translateX(1px);
        `}
`;
