import PropTypes from "prop-types";

import Head from "components/shared/head/Head";
import Header from "components/shared/pageLayout/header/Header";
import Footer from "components/shared/pageLayout/footer/Footer";

import { Layout } from "./PageLayout.styled";

function PageLayout({
    siteName,
    title,
    description,
    keywords,
    canonical,
    children,
    showMenu
}) {
    return (
        <Layout>
            <Head
                siteName={siteName}
                title={title}
                description={description}
                keywords={keywords}
                canonical={canonical}
            />
            <Header showMenu={showMenu} />
            {children}
            <Footer />
        </Layout>
    );
}

PageLayout.propTypes = {
    siteName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    canonical: PropTypes.string,
    children: PropTypes.node.isRequired,
    showMenu: PropTypes.bool
};

PageLayout.defaultProps = {
    siteName: "Nationale Kunst & Cultuur Cadeaukaart",
    title: "Nationale Kunst & Cultuur Cadeaukaart | Geef een cultureel cadeau",
    description:
        "De Nationale Kunst & Cultuur Cadeaukaart voor theater, musicals, musea en monumenten door heel Nederland. Bestel nu voor 17:00 > morgen bezorgd!",
    keywords: [
        "Kunst & Cultuur Cadeau",
        "nationaal",
        "kunst",
        "cultuur",
        "cadeaukaart"
    ],
    canonical: undefined,
    showMenu: true
};

export default PageLayout;
