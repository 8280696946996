import { useEffect, useRef, useState } from "react";

import { scrollToRef } from "helpers/utils";

import IconChevronUp from "components/ui/icons/IconChevronUp";

import Contact from "../contact/Contact";
import Shipment from "../shipment/Shipment";
import PaymentMethods from "../paymentMethods/PaymentMethods";
import Links from "../links/Links";
import Terms from "../terms/Terms";

import {
    Container,
    BlobContainer,
    BlobImage,
    IconWrapper
} from "./MobileFooter.styled";
import Newsletter from "../newsletter/Newsletter";

const navItems = [
    {
        url: "/helpdesk/",
        label: "Veelgestelde vragen"
    },
    {
        url: "/over-ons/",
        label: "Over ons"
    },
    {
        url: "https://www.cadeaubon.nl/",
        label: "Andere cadeaubonnen"
    }
];

function FooterMobile() {
    const [open, setOpen] = useState(false);
    const footerRef = useRef();
    const blobHeight = 38;

    useEffect(() => {
        if (open) {
            scrollToRef(footerRef, blobHeight);
        }
    }, [open]);

    const handleFooter = () => {
        setOpen(!open);
        scrollToRef(footerRef, blobHeight);
    };

    return (
        <Container ref={footerRef} open={open}>
            <BlobContainer onClick={() => handleFooter()}>
                <BlobImage src="/images/footerblub.svg" alt="Footer Blob" />
                <IconWrapper open={open}>
                    <IconChevronUp size={24} fill="var(--color-white)" />
                </IconWrapper>
            </BlobContainer>
            <Newsletter />
            <Shipment />
            <Contact />
            <Links items={navItems} />
            <PaymentMethods />
            <Terms />
        </Container>
    );
}

export default FooterMobile;
